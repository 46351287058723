import { Component } from '@angular/core';

@Component({
  selector: 'rd-loading',
  templateUrl: './rd-loading.html',
  styleUrls: ['./rd-loading.css']
})
export class RdLoading {

}
