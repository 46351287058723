import { Injectable } from '@angular/core';
import {HttpHeaders} from '@angular/common/http';


@Injectable()
export class CustomHeaders {
    private headers: HttpHeaders;

    constructor(){}

    public getCustomHeader(){
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('Content-Type', 'application/json');
        if(window.localStorage.getItem('auth_key_cms_admin')){
            this.headers = this.headers.set('Authorization', 'Basic ' + window.localStorage.getItem('auth_key_cms_admin'));
        }
        return {'headers': this.headers};
    }

    isUserLoggedIn(){
        return !!window.localStorage.getItem('auth_key_cms_admin');
    }
}