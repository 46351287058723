import { Injectable } from '@angular/core';
import {RequestOptions} from '@angular/http';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {Base64Service} from "../common/base64.service";
import {Router} from "@angular/router";
import {CustomHeaders} from "../common/custom-header.service";

declare var jsSHA: any;

@Injectable()
export class AuthService{
    isAuthenticated: boolean = false;
    userData: any;
    shaObj: any;
    currentUser: any;

    constructor(private http: HttpClient, private base64: Base64Service, private router: Router, private customHeaders: CustomHeaders) {
    }

    init(){
    }

    authenticatenow(usercreds) {
        this.userData = {
            username: usercreds.username,
            password: usercreds.password
        };

        let body = JSON.stringify(
            {
                username: usercreds.username,
                password: this.encodeToSha256(usercreds.password)
            });
        let headers = this.customHeaders.getCustomHeader();

        return new Promise((resolve) => {
            this.http.post('/api/v1/login', body, headers)
                .subscribe((data) => {
                    var json = data;
                    // Successfull response
                    if(json['status']) {
                        this.setCredentials();
                        this.setCurrentUser(json['user'], json['customer'])
                        this.isAuthenticated = true;
                    }
                    //
                    else{
                        console.log('invalid user');
                    }
                    resolve(this.isAuthenticated);
                }

            )
        })
    }

    encodeToSha256(clean_string){
        this.shaObj = new jsSHA("SHA-256", "TEXT");
        this.shaObj.update(clean_string);
        return this.shaObj.getHash("HEX");
    }

    setCredentials(){
        console.log('setCredentials', this.userData);
        var encoded = this.base64.encodeStr(this.userData.username+ ':' +this.userData.password);
        window.localStorage.setItem('auth_key_cms_admin', encoded);
    }

    setCurrentUser(user, customer){
        delete user.customer_id;
        user.customer = customer;
        this.currentUser = user;

        window.localStorage.setItem('user_cms_admin', JSON.stringify(user));
    }

    isLogged(){
        return window.localStorage.getItem('auth_key_cms_admin');
    }

    getCurrentUser(){
        if(!this.isLogged()){
            return null;
        }
        return JSON.parse(window.localStorage.getItem('user_cms_admin'));
    }

    getCurrentSiteHostName(){
        return window.localStorage.getItem('site_hostName_cms_admin');
    }

    setCurrentSiteHostName(hostName){
        window.localStorage.setItem('site_hostName_cms_admin', hostName);
    }

    removeCurrentSiteHostName(){
        window.localStorage.removeItem('site_hostName_cms_admin');
    }

    getLoggedInUser(){
        if(!this.isLogged()){
            return null;
        }
        return JSON.parse(window.localStorage.getItem('user_cms_admin'));
    }

    clearCredentials(){
        this.currentUser = null;
        window.localStorage.removeItem('auth_key_cms_admin');
        window.localStorage.removeItem('user_cms_admin');
        this.router.navigate(['/login']);
    }
}
