import {Component, OnInit} from "@angular/core";
import { HttpClient } from '@angular/common/http';
import {CustomHeaders} from "../services/common/custom-header.service";
import { Router} from '@angular/router';
import {EventsService} from "../services/common/event.service";
import {AuthService} from "../services/auth/auth.service";

declare var $: any;

@Component({
    templateUrl: './front.html',
    styleUrls: ['./front.css']
})
export class FrontComponent implements OnInit {
    sites:any[]=[];
    showNewSite:boolean = false;
    private selectedSite:any;

    constructor(private auth: AuthService, private http: HttpClient, private customHeaders: CustomHeaders, private eventsService: EventsService, private router: Router){
        this.getSites();
        this.eventsService.broadcast({
            'key': 'pageChanged',
            'data': 'front'
        });
    }

    ngOnInit() {

    }

    getSites(){
        this.http.get('/api/v1/site')
            .subscribe((data) => {
                var json:any = data;
                this.sites = json;
            });
    }

    selectSite(site){
        console.log(site);
        this.selectedSite = site;
        this.auth.setCurrentSiteHostName(site.hostname);
        this.eventsService.broadcast({
            'key': 'siteNameUpdated',
            'data': site.name
        });
        this.eventsService.broadcast({
            'key': 'pageChanged',
            'data': 'modules'
        });
        this.router.navigate(['/modules']);
    }

    newSiteFunc(name, hostname) {
        console.log('New:', name, hostname);

        let headers = this.customHeaders.getCustomHeader();
        var data = {
            'name': name.value,
            'hostname': hostname.value + '.biokupon.dk',
        }
        this.http.post('/api/v1/site', data, headers)
            .subscribe(
                data => {
                    console.log("New site created succesfully", data);
                    this.getSites();

                }, error => {
                    console.log("Error while creating new site", error);
                }
            );
    }
}
