import {Component, EventEmitter, Input, Output} from "@angular/core";


@Component({
    selector: 'footer-module',
    templateUrl: './footer-module.html',
    styleUrls: ['./footer-module.css']
})

export class FooterModule{
    @Input() module: any;
    @Output() saveSite :EventEmitter<any> = new EventEmitter();

    constructor() {
    }

}