import {Component} from "@angular/core";
import { Router} from '@angular/router';
import {AuthService} from "../services/auth/auth.service";
import {CustomHeaders} from "../services/common/custom-header.service";
import {EventsService} from "../services/common/event.service";
import {HttpClient} from "@angular/common/http";
import {Item} from "../models/item";

@Component({
    templateUrl: './orders.html',
    styleUrls: ['./orders.css']
})
export class OrdersComponent {
    orders:any[]=[];
    pages:any={};
    loadingOrders:boolean = true;
    search:any={};
    orderStatus:any={
        created: false,
        pending_dibs: false,
        pending_discount_coupons: false,
        reservation_timeout: false,
        reservation_timeout_but_paid: false,
        reservation_accepted: false,
        payment_completed: true,
        order_not_found_after_payment: false,
        md5_did_not_match: false
    };
    private sort:any;
    private loadingItems: boolean;
    public items:Item[]=[];
    public selectedItem:Item;

    constructor(private auth: AuthService, private headers: CustomHeaders, private http: HttpClient, private eventsService: EventsService, private router: Router) {
        this.eventsService.broadcast({
            'key': 'pageChanged',
            'data': 'orders'
        });
        this.reset();
        this.resetSort();
        // Set a default sort on created
        this.sort.created = true;
        window.scrollTo(0,0);
        this.showOrderList();
        this.getItems();

    }

    resetSort(){
        this.sort = {
            created: false,
            createdSort: 'desc',
            orderId: false,
            orderIdSort: 'desc',
            name: false,
            nameSort: 'desc',
            email: false,
            emailSort: 'desc',
            msisdn: false,
            msisdnSort: 'desc',
            status: false,
            statusSort: 'desc'
        };
    }

    reset(){
        this.pages = {
            offset: 0,
            limit: 10,
            total: 0
        }
    }

    changePage(offset){
        console.log('changing page', offset);
        this.pages.offset = offset;
        this.showOrderList();
    }

    showOrderList(){
        this.loadingOrders = true;
        let search = this.search.searchfield;

        let sort = this.getSortDict();
        if(!search){
            search = 'IGNORE';
        }
        let statuses = this.getStatuses();
        let url = '/api/v1/order/offset/'+this.pages.offset+'/limit/'+this.pages.limit+'/site/'+this.auth.getCurrentSiteHostName()+'/search/'+search+'/'+sort.sortColumn+'/'+sort.sortDirection+'/status/'+statuses;
        let itemId ='-1';
        if(this.selectedItem){
            itemId = this.selectedItem._id;
        }
        url+='/item/'+itemId;
        this.http.get(url, this.headers.getCustomHeader())
            .subscribe((data) =>{
                var json = data;
                this.loadingOrders = false;
                this.orders = json['search_data'];
                this.pages.total = json['total'];
                this.pages.maxPage = Math.ceil(this.pages.total / this.pages.limit);
            },
            (error) =>{
                this.loadingOrders = false;
                /*
                 this.alertsData.push({
                 type: 'danger',
                 msg: 'Could not connect to the server, try again later.'
                 });
                 */
            });
    }

    viewOrder(order){
        this.router.navigate(['/orders/'+ order._id]);
    }

    onchangeSearchField(){
        var tmpSearchfield='';
        setTimeout(() => {
            tmpSearchfield = this.search.searchfield;
        }, 10);
        setTimeout(() => {
            if (tmpSearchfield === this.search.searchfield) {
                this.showOrderList();
            }
        }
        , 1000);
    }

    changeOrderStatus(){
        this.showOrderList();
    }

    toggleSort(menu){
        var before = this.sort[menu];
        var beforeDirection = this.sort[menu+'Sort'];
        this.resetSort();
        this.sort[menu] = before;
        this.sort[menu+'Sort'] = beforeDirection;
        if(this.sort[menu]){
            this.sort[menu+'Sort'] = this.sort[menu+'Sort'] === 'asc' ? 'desc': 'asc';
        }
        else{
            this.sort[menu+'Sort'] = 'desc';
            this.sort[menu] = true;
        }
        this.showOrderList();
    }

    getSortDict(){
        // default if nothing is found
        var sortColumn = 'created';
        var sortDirection = 'desc';

        if(this.sort.orderId){
            sortColumn = 'order_number';
            sortDirection = this.sort.orderIdSort;
        }
        else if(this.sort.name){
            sortColumn = 'user_inputs.name';
            sortDirection = this.sort.nameSort;
        }
        else if(this.sort.email){
            sortColumn = 'user_inputs.email';
            sortDirection = this.sort.emailSort;
        }
        else if(this.sort.msisdn){
            sortColumn = 'user_inputs.msisdn';
            sortDirection = this.sort.msisdnSort;
        }
        else if(this.sort.created){
            sortColumn = 'created';
            sortDirection = this.sort.createdSort;
        }
        else if(this.sort.status){
            sortColumn = 'order_status';
            sortDirection = this.sort.statusSort;
        }
        return {
            sortColumn: sortColumn,
            sortDirection: sortDirection
        }
    }

    getStatuses(){
        console.log('get statuses');
        var statuses = [];
        for(var key in this.orderStatus){
            console.log(key);
            if(this.orderStatus[key]){
                statuses.push(key);
            }
        }
        console.log('statuses');
        console.log(statuses);
        var responseStr = statuses.toString();
        console.log(responseStr);
        if(responseStr.length === 0){
            return 'none';
        }
        return responseStr;
    }

    getItems(){
        this.loadingItems = true;
        let url = '/api/v1/item/all/'+this.auth.getCurrentSiteHostName();
        this.http.get(url, this.headers.getCustomHeader())
            .subscribe((data) =>{
                    var json = data;
                    this.loadingItems = false;
                    let item = new Item();
                    item.name = 'Select item';
                    item._id = '-1';
                    this.selectedItem = item;
                    this.items.push(item);
                    let items = json as Item[];
                    for(let i=0;i<items.length;i++){
                        this.items.push(items[i]);
                    }
                    console.log('items', this.items);
                },
                (error) =>{
                    this.loadingItems = false;
                });
    }

    getItemNames(order){
        let itemName = '';
        for(let i=0;i<order['basket'].length;i++){
            if(itemName.length == 0){
                itemName = order['basket'][i]['item_name'];
            }
            else{
                itemName += ', '+order['basket'][i]['item_name'];
            }
        }
        return itemName;
    }

    getDiscountCoupons(order){
        let discountCoupons = '';
        console.log(order);
        for(let i=0;i<order['basket'].length;i++){
            for(let j=0;j<order['basket'][i]['discount_coupons'].length;j++){
                if(discountCoupons.length == 0){
                    discountCoupons = order['basket'][i]['discount_coupons'][j]['code'] + ' ('+order['basket'][i]['discount_coupons'][j]['seats']+')';
                }
                else{
                    discountCoupons += ', '+order['basket'][i]['discount_coupons'][j]['code'] + ' ('+order['basket'][i]['discount_coupons'][j]['seats']+')';
                }
            }
        }
        return discountCoupons;
    }

    getProductIds(order){
        let productNames = '';
        for(let i=0;i<order['basket'].length;i++){
            if(productNames.length == 0){
                productNames = order['basket'][i]['product']['product_id'];
            }
            else{
                productNames += ', '+order['basket'][i]['product']['product_id'];
            }
        }
        return productNames;
    }
}