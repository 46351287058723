import {Component} from "@angular/core";
import {Router} from "@angular/router";
import {AuthService} from "../services/auth/auth.service";
import {EventsService} from "../services/common/event.service";

@Component({
    templateUrl: './login.html',
    styleUrls: ['./login.css']
})
export class LoginComponent{
    alertsData: any[] = [];

    localUser = {
        username: '',
        password: ''
    }

    constructor(private auth: AuthService, private router: Router, private eventService: EventsService) {
    }


    login() {
        let checknow = this.auth.authenticatenow(this.localUser);
        checknow.then((res) => {
            if (res) {
                this.eventService.broadcast('login');
                this.router.navigate(['/']);
            }
            else {
                console.log('invalid user');
            }
        })
    }
}