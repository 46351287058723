import {Injectable} from '@angular/core';

import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

@Injectable()
export class AuthManager implements CanActivate {
    constructor(private router: Router) {
    }


    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if(next && next.url && next.url[0] && next.url[0].path == 'login'){
            return !window.localStorage.getItem('auth_key_cms_admin');
        }
        
        if(window.localStorage.getItem('auth_key_cms_admin')){
            return true;
        }

        this.router.navigate(['/login']);
        return false;
    }

}