import {Component} from "@angular/core";
import {ActivatedRoute} from '@angular/router';
import {CustomHeaders} from "../services/common/custom-header.service";
import {EventsService} from "../services/common/event.service";
import {HttpClient} from "@angular/common/http";


@Component({
    templateUrl: './single-order.html',
    styleUrls: ['./single-order.css']
})
export class SingleOrderComponent {
    loadingOrder:boolean=true;
    private orderId:string;
    private order:any={};

    constructor(private headers: CustomHeaders, private http: HttpClient, private eventsService: EventsService, private route: ActivatedRoute) {
        this.orderId = this.route.snapshot.params['order_id'];
        this.eventsService.broadcast({
            'key': 'pageChanged',
            'data': 'orders'
        });

        this.getOrder();
    }


    getOrder(){
        this.loadingOrder = true;
        var url = '/api/v1/order/' + this.orderId+ '/full';
        this.http.get(url, this.headers.getCustomHeader())
            .subscribe((data) =>{
                var json = data;
                this.order = json;
                this.loadingOrder = false;
            },
            (error) =>{
                this.loadingOrder = false;
            });

    }
}