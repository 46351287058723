import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortItemsByPrioirtyFilter'
})
export class SortItemsByPrioirtyPipe implements PipeTransform {
    transform(items: any[], filter: Object): any {
        items.sort((a: any, b: any) => {
            if (a.priority < b.priority) {
                return -1;
            } else if (a.priority > b.priority) {
                return 1;
            } else {
                return 0;
            }
        });
        return items;
    }
}