//https://plnkr.co/edit/PFfebmnqH0eQR9I92v0G?p=preview

import {Component, ChangeDetectorRef} from "@angular/core";
import {Router, ActivatedRoute} from '@angular/router';
import {AuthService} from "../services/auth/auth.service";
import {CustomHeaders} from "../services/common/custom-header.service";
import {EventsService} from "../services/common/event.service";
import {HttpClient} from "@angular/common/http";

//import * as $ from 'jquery'; window["$"] = $; window["jQuery"] = $;
declare var $ :any;

@Component({
    templateUrl: './item.html',
    styleUrls: ['./item.css']
})
export class ItemComponent {
    private base64textString:string="";
    private name:string;
    private urlName:string;
    private teaserImg:string;
    item:any={
        user_input_fields: {
            name: true,
            nameRequired: true,
            email: true,
            emailRequired: true,
            address: false,
            addressRequired: false,
            msisdn: false,
            msisdnRequired: false,
            age: false,
            ageRequired: false,
            gender: false,
            genderRequired: false,
            partner: false,
            partnerRequired: false,
            partners: [],
            matasNumber: false,
            matasNumberRequired: false,
            matasNumberValidation: false
        },
        canBuy: false
    };
    private errorMessage:string;
    private loading:boolean=false;
    eventPlaceName:string;
    products:any[]=[];
    selectedProduct:any;
    productFilter:string;
    eventPlaces:any[]=[];
    private isEdit:boolean=false;
    partnerName:string;
    public invalid = {
        name: false,
        urlName: false,
        urlNameContent: false,
        headerText: false,
        teaserText: false,
        teaserImg: false,
        eventPlace: false
    };

    public editEventPlaceIndex: number= -1;

    public editConfigToolbar: any = {
        "editable": true,
        "spellcheck": true,
        "height": "300px",
        "minHeight": "300px",
        "width": "auto",
        "minWidth": "0",
        "translate": "yes",
        "enableToolbar": true,
        "showToolbar": true,
        "placeholder": "Enter text here...",
        "imageEndPoint": "",
        "toolbar": [
            ["bold", "italic", "underline", "strikeThrough", "superscript", "subscript"],
            ["fontName", "fontSize", "color"],
            ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull", "indent", "outdent"],
            ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
            ["paragraph", "blockquote", "removeBlockquote", "horizontalLine", "orderedList", "unorderedList"],
            ["link", "unlink", "image", "video"]
        ]
    };
    public hostname:string;

    constructor(private auth: AuthService, private headers: CustomHeaders, private http: HttpClient, private eventsService: EventsService, private route: ActivatedRoute, private router: Router, private chRef: ChangeDetectorRef) {
        let id = this.route.snapshot.params['id'];
        this.isEdit = !!id;

        if(this.isEdit){
            this.getItem(id);
        }
        this.eventsService.broadcast({
            'key': 'pageChanged',
            'data': 'items'
        });
        this.getProducts();
        this.hostname = this.auth.getCurrentSiteHostName();

    }

    ngOnInit() {
        /*
        console.log('NG ON INIT');
        $('#froalaEditorItem').froalaEditor({
            key: 'lgohcC-11zdbA3jd=='
        });
        */
    }

    getProducts(){
        this.http.get('/voucherapi/v1/products/list_all_none_sub_none_customer')
            .subscribe((data) => {
                var json:any = data;
                this.products = json;
                if(this.products){
                    this.selectedProduct = this.products[0];
                }
            }),
            (error) =>{
                console.log(error);
            };
    }

    convertTeaserImage(evt){
        var files = evt.target.files;
        var file = files[0];

        if (files && file) {
            var reader = new FileReader();
            reader.onload =this._handleReaderLoaded.bind(this);
            reader.readAsBinaryString(file);
        }
    }

    _handleReaderLoaded(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.base64textString= btoa(binaryString);
        this.item.teaserImg = this.base64textString;
    }

    saveItem(){
        this.loading = true;
        let data = {
            name: this.item.name,
            url_name: this.item.urlName,
            header_text: this.item.headerText,
            teaser_text: this.item.teaserText,
            created_by_text: this.item.createdByText,
            image: this.item.teaserImg,
            trailer_link: this.item.trailerLink,
            site_hostname: this.auth.getCurrentSiteHostName(),
            user_input_fields: {
                name:this.item.user_input_fields.name,
                name_required:this.item.user_input_fields.nameRequired,
                email:this.item.user_input_fields.email,
                email_required:this.item.user_input_fields.emailRequired,
                address:this.item.user_input_fields.address,
                address_required:this.item.user_input_fields.addressRequired,
                msisdn:this.item.user_input_fields.msisdn,
                msisdn_required:this.item.user_input_fields.msisdnRequired,
                age:this.item.user_input_fields.age,
                age_required:this.item.user_input_fields.ageRequired,
                gender:this.item.user_input_fields.gender,
                gender_required:this.item.user_input_fields.genderRequired,
                partner:this.item.user_input_fields.partner,
                partner_required:this.item.user_input_fields.partnerRequired,
                partners:this.item.user_input_fields.partners,
                matas_number: this.item.user_input_fields.matasNumber,
                matas_number_required: this.item.user_input_fields.matasNumberRequired,
                matas_number_validation: this.item.user_input_fields.matasNumberValidation
            },
            event_places : this.eventPlaces,
            dibs_payment: this.item.dibsPayment,
            epay_payment: this.item.epayPayment,
            discount_coupon: this.item.discountCoupon,
            price: this.item.price,
            can_buy: this.item.canBuy
        };

        console.log("data: ", data);

        if(this.isEdit){
            this.http.put('/api/v1/item/'+this.item.id, data, this.headers.getCustomHeader())
                .subscribe((data) => {
                    this.loading = false;
                    this.router.navigate(['/items']);
                }),
                (error) =>{
                    this.loading = false;
                    this.errorMessage = 'Error saving item';
                };
        }
        else{
            this.http.post('/api/v1/item', data, this.headers.getCustomHeader())
                .subscribe((data) => {
                    this.loading = false;
                    this.router.navigate(['/items']);
                }),
                (error) =>{
                    this.loading = false;
                    this.errorMessage = 'Error saving item';
                };
        }
    }

    isNameValid(){
        return !(!this.item.name || this.item.name.length == 0);
    }

    isUrlNameValid(){
        return !(!this.item.urlName || this.item.urlName.length == 0)
    }

    isUrlNameContentValid(){
        return this.item.urlName.search(/[a-z0-9_]+/) !== -1 && this.item.urlName.indexOf(' ') === -1;
    }

    isUrlValid(urlName){
        return urlName.search(/[a-z0-9_]+/) !== -1 && urlName.indexOf(' ') === -1;
    }

    isHeaderTextValid(){
        return !(!this.item.headerText || this.item.headerText.length == 0);
    }

    isTeaserTextValid(){
        return !(!this.item.teaserText || this.item.teaserText.length == 0);
    }

    isTeaserImageValid(){
        return !(!this.item.teaserImg || this.item.teaserImg.length == 0);
    }

    isEventPlaceAdded(){
        return this.eventPlaces && this.eventPlaces.length > 0;
    }

    isInputValid(){
        if(!this.isNameValid()){
            return false;
        }

        if(!this.isUrlNameValid()){
            return false;
        }

        if(!this.isUrlNameContentValid()) {
            return false;
        }

        if(!this.isHeaderTextValid()){
            return false;
        }

        if(!this.isTeaserTextValid()){
            return false;
        }

        if(!this.isTeaserImageValid()){
            return false;
        }

        if(!this.isEventPlaceAdded()){
            return false;
        }
        return true;
    }

    addEventPlace(){
        this.eventPlaces.push({
            event_place_name: this.eventPlaceName,
            voucher_product_id: this.selectedProduct._id,
            voucher_product_name: this.selectedProduct.name
        })
        for(var i=0;i<this.products.length;i++){
            if(this.products[i]._id === this.selectedProduct._id){
                this.products.splice(i, 1);
                break;
            }
        }
        this.eventPlaceName='';
        var productFilterTmp = this.productFilter;
        this.productFilter = '';
        this.chRef.detectChanges();
        this.productFilter = productFilterTmp;
    }

    setEventPlaceName(){
        this.eventPlaceName = this.selectedProduct.name;
    }

    isEventPlaceValid(){
        if(!this.eventPlaceName){
            return false;
        }

        if(this.selectedProduct){
            for(var i=0;i<this.eventPlaces.length;i++){
                if(this.eventPlaces[i].voucher_product_id === this.selectedProduct._id){
                    return false;
                }
                else if(this.eventPlaces[i].event_place_name === this.eventPlaceName){
                    return false;
                }
            }
        }

        return true;
    }

    removeEventPlace(index){
        this.products.push({
            '_id':  this.eventPlaces[index]._id,
            'name': this.eventPlaces[index].name
        });
        this.eventPlaces.splice(index, 1);
    }

    editEventPlace(index){
        this.editEventPlaceIndex = index;
    }

    closeEdit(){
        this.editEventPlaceIndex = -1;
    }

    getItem(id){
        this.http.get('/api/v1/item/by_id/'+id)
            .subscribe((data) => {
                var item = data;
                this.item.id = item['_id'];
                this.item.name = item['name'];
                this.item.urlName = item['url_name'];
                this.item.headerText = item['header_text'];
                this.item.teaserText = item['teaser_text'];
                this.item.teaserImg = item['image'];
                this.item.trailerLink = item['trailer_link'];
                this.item.createdByText = item['created_by_text'];
                this.item.copyOfItem = item['copy_of_item_name'];
                this.eventPlaces = item['event_places'];
                this.item.user_input_fields.name = item['user_input_fields']['name'];
                this.item.user_input_fields.nameRequired = item['user_input_fields']['name_required'];
                this.item.user_input_fields.email = item['user_input_fields']['email'];
                this.item.user_input_fields.emailRequired = item['user_input_fields']['email_required'];
                this.item.user_input_fields.address = item['user_input_fields']['address'];
                this.item.user_input_fields.addressRequired = item['user_input_fields']['address_required'];
                this.item.user_input_fields.msisdn = item['user_input_fields']['msisdn'];
                this.item.user_input_fields.msisdnRequired = item['user_input_fields']['msisdn_required'];
                this.item.user_input_fields.age = item['user_input_fields']['age'];
                this.item.user_input_fields.ageRequired = item['user_input_fields']['age_required'];
                this.item.user_input_fields.gender = item['user_input_fields']['gender'];
                this.item.user_input_fields.genderRequired = item['user_input_fields']['gender_required'];
                this.item.user_input_fields.partners = item['user_input_fields']['partners'];
                this.item.user_input_fields.partner = item['user_input_fields']['partner'];
                this.item.user_input_fields.partnerRequired = item['user_input_fields']['partner_required'];
                this.item.user_input_fields.matasNumber = item['user_input_fields']['matas_number'];
                this.item.user_input_fields.matasNumberRequired = item['user_input_fields']['matas_number_required'];
                this.item.user_input_fields.matasNumberValidation = item['user_input_fields']['matas_number_validation'];
                // Payment
                this.item.dibsPayment = item['dibs_payment'];
                this.item.epayPayment = item['epay_payment'];
                this.item.discountCoupon = item['discount_coupon'];
                this.item.price = item['price'];
                this.item.canBuy = item['can_buy'];
            }),
            (error) =>{
                console.log(error);
            };
    }

    addPartner(){
        this.item.user_input_fields.partners.push(this.partnerName);
        this.partnerName = '';
    }

    public options: Object = {
        toolbarButtons: ['paragraphFormat', 'paragraphStyle', 'undo', 'redo' , '|', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'outdent', 'indent', 'clearFormatting', 'insertTable', 'html'],
        toolbarButtonsXS: ['paragraphFormat', 'paragraphStyle', 'undo', 'redo' , '|', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'outdent', 'indent', 'clearFormatting', 'insertTable', 'html'],
        toolbarButtonsSM: ['paragraphFormat', 'paragraphStyle', 'undo', 'redo' , '|', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'outdent', 'indent', 'clearFormatting', 'insertTable', 'html'],
        toolbarButtonsMD: ['paragraphFormat', 'paragraphStyle', 'undo', 'redo' , '|', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'outdent', 'indent', 'clearFormatting', 'insertTable', 'html'],
    };

    public eventPlaceUrlValid(){
        for(let i=0;i<this.eventPlaces.length;i++){
            if(!this.eventPlaces[i].url_name){
                continue;
            }
            for(let j=0;j<this.eventPlaces.length;j++){
                if(!this.eventPlaces[j].url_name){
                    continue;
                }

                if(i!=j && this.eventPlaces[i].url_name.toLowerCase() == this.eventPlaces[j].url_name.toLowerCase()){
                    return false;
                }
            }

            if(!this.isUrlValid(this.eventPlaces[i].url_name)){
                return false;
            }
        }
        return true;
    }

}