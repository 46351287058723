import {Component} from "@angular/core";
import {Http, RequestOptions} from "@angular/http";
import {AuthService} from "../services/auth/auth.service";
import {CustomHeaders} from "../services/common/custom-header.service";
import {EventsService} from "../services/common/event.service";
import {HttpClient} from "@angular/common/http";

declare var $: any;

@Component({
    templateUrl: './modules.html',
    styleUrls: ['./modules.css']
})
export class ModulesComponent {
    private hostName: string;
    private site: any;
    loading: any = {
        site: true
    };

    constructor(private auth: AuthService, private headers: CustomHeaders, private http: HttpClient, private eventsService: EventsService){
        this.eventsService.broadcast({
            'key': 'pageChanged',
            'data': 'modules'
        });

        this.hostName = auth.getCurrentSiteHostName();
        this.getSiteModules();
    }

    getSiteModules(){
        this.http.get('/api/v1/site/' + this.hostName +'?images=true', this.headers.getCustomHeader())
            .subscribe((data) => {
                this.site = data;
                console.log(this.site);
                this.loading.site = false;
            });
    }

    saveSite(){
        console.log('SAVE SITE', this.site.modules);
        var data = {
            modules: this.site.modules
        };
        this.http.put('/api/v1/site/site_modules/'+this.site._id, data, this.headers.getCustomHeader())
            .subscribe((data) => {
                console.log('success');
            }),
            (error) =>{
                console.log('failed');
            };
    }

}
