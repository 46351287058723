import {Component, EventEmitter, Input, Output, OnInit} from "@angular/core";
import {AuthService} from "../../services/auth/auth.service";
import {CustomHeaders} from "../../services/common/custom-header.service";
import {HttpClient} from "@angular/common/http";

@Component({
    selector: 'product-list-module',
    templateUrl: './product-list-module.html',
    styleUrls: ['./product-list-module.css']
})

export class ProductListModule implements OnInit{
    private hostName:string;
    private loadingItems:boolean=false;
    private loading:any={
    };
    items:any[]=[];
    selectedItem:any;
    viewableItems:any[]=[];
    dummyCounter:number=0;
    @Input() module: any;
    @Output() saveSite :EventEmitter<any> = new EventEmitter();

    constructor(private auth: AuthService, private headers: CustomHeaders, private http: HttpClient) {
        this.hostName = auth.getCurrentSiteHostName();
        this.getItems();
    }

    ngOnInit() {
        this.rebuildViewableItems();
    }

    loadItems(){
        for(var i=0;i<this.viewableItems.length;i++){
            if(this.viewableItems[i].loading){
                var item = this.viewableItems[i];
                var url = '/api/v1/item/by_id/' + this.viewableItems[i].id;
                this.http.get(url, this.headers.getCustomHeader())
                    .subscribe((data) =>{
                        var json = data;
                        item.loading = false;
                        item.image = json['image'];
                        item.name = json['name'];
                        item.headerText = json['header_text'];
                        item.teaserText = json['teaser_text'];
                        this.loadItems();
                    },
                    (error) =>{
                        item.loading = false;
                    });
                break;
            }
        }
    }

    getItems(){
        this.loadingItems = true;
        var url = '/api/v1/item/all/' + this.hostName;
        this.http.get(url, this.headers.getCustomHeader())
            .subscribe((data) =>{
                    var json:any = data;
                    this.loadingItems = false;
                    this.items = json;
                    this.selectedItem=this.items[0];
                },
                (error) =>{
                    this.loadingItems = false;
                });
    }

    isAddItemValid(){

        return true;
    }

    addItem(){
        var lastPriority = 0;
        for(var i=0;i<this.module.items.length;i++){
            if(this.module.items[i].priority > lastPriority){
                lastPriority = this.module.items[i].priority;
            }
        }
        lastPriority++;

        this.module.items.push({
            item_id: this.selectedItem._id,
            priority: lastPriority
        });

        this.viewableItems.push({
            loading: true,
            id: this.selectedItem._id,
            priority: lastPriority
        });

        this.loadItems();
        this.saveSite.emit();
    }

    changePriority(item, direction){
        var from = item.priority;
        var to:number=0;
        if(direction === 'up'){
            to = item.priority-1;
            for(var i=0;i<this.viewableItems.length;i++){
                if(item.priority-1 === this.viewableItems[i].priority){
                    this.viewableItems[i].priority = item.priority;
                    item.priority = item.priority-1;
                    break;
                }
            }
        }
        else if(direction === 'down'){
            to = item.priority+1;
            for(var i=0;i<this.viewableItems.length;i++){
                if(item.priority+1 === this.viewableItems[i].priority){
                    this.viewableItems[i].priority = item.priority;
                    item.priority = item.priority+1;
                    break;
                }
            }
        }
        this.changeModuleItemPriority(from, to);
        this.saveSite.emit();
        this.dummyCounter++;
    }

    changeModuleItemPriority(from, to){
        for(var i=0; i<this.module.items.length;i++){
            if(this.module.items[i].priority === from){
                this.module.items[i].priority = to;
            }
            else if(this.module.items[i].priority === to){
                this.module.items[i].priority = from;
            }
        }
    }


    removeItem(item){
        for(var i=0; i<this.module.items.length;i++){
            if(item.priority === this.module.items[i].priority){
                this.module.items.splice(i, 1);
            }

            else if(item.priority < this.module.items[i].priority){
                this.module.items[i].priority--;
            }
        }

        this.saveSite.emit();
        this.rebuildViewableItems();
    }

    rebuildViewableItems(){
        this.viewableItems=[];
        for(var i=0; i<this.module.items.length;i++){
            this.viewableItems.push({
                loading: true,
                id: this.module.items[i].item_id,
                priority: this.module.items[i].priority
            });
        }
        this.dummyCounter++;
        this.loadItems();
    }


}