import {Component, EventEmitter, Input, Output} from "@angular/core";


@Component({
    selector: 'nav-module',
    templateUrl: './nav-module.html',
    styleUrls: ['./nav-module.css']
})

export class NavModule{
    private base64textString:string='';

    @Input() module: any;
    @Output() saveSite :EventEmitter<any> = new EventEmitter();

    constructor() {}

    convertImage(evt){
        var files = evt.target.files;
        var file = files[0];

        if (files && file) {
            var reader = new FileReader();
            reader.onload =this._handleReaderLoaded.bind(this);
            reader.readAsBinaryString(file);
        }
    }

    _handleReaderLoaded(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.base64textString= btoa(binaryString);
        this.module.logo.base64 = this.base64textString;
        this.saveSite.emit();
    }

    //Used for color
    save() {
        this.saveSite.emit();
    }
}
