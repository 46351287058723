import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
    selector: 'paging',
    templateUrl: './paging.html',
    styleUrls: ['./paging.css']
})

export class Paging{
    currentPage: number;
    leftPageBtn: number;
    middlePageBtn: number;
    rightPageBtn: number;
    @Output() changePage :EventEmitter<any> = new EventEmitter();
    Math: any;

    @Input() pages: any;

    constructor() {
        this.pages={};
        this.Math = Math;
        this.currentPage = 1;
        this.leftPageBtn = 3;
        this.middlePageBtn = 4;
        this.rightPageBtn = 5;
    }

    changePageNumber(pageNumber) {
        console.log('changing pagenumber from: ' + this.currentPage + ' to ' + pageNumber);
        this.currentPage = pageNumber;
        console.log('Changing page number', this.currentPage);
        if(pageNumber +3 >= this.pages.maxPage && this.pages.maxPage > 6){
            this.leftPageBtn = this.pages.maxPage-4;
            this.middlePageBtn = this.pages.maxPage-3;
            this.rightPageBtn = this.pages.maxPage-2;
        }
        else if(pageNumber > 4 && this.pages.maxPage > 6){
            this.leftPageBtn = 3 + (pageNumber-4);
            this.middlePageBtn = 4 + (pageNumber-4);
            this.rightPageBtn = 5 + (pageNumber-4);
        }
        else{
            this.leftPageBtn = 3;
            this.middlePageBtn = 4;
            this.rightPageBtn = 5;
        }
        var offset = (pageNumber*this.pages.limit - this.pages.limit);
        this.changePage.emit(offset); // pass along the data which whould be used by the parent component
        console.log('Changing page number', this.currentPage);
    }
}