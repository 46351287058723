import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'reduceListByStringFilter'
})
export class ReduceListByStringPipe implements PipeTransform {
    transform(items: any[], filter: Object): any {
        if(!filter){
            return items;
        }

        var newItems = [];
        var lowCaseFilter = (filter+"").toLowerCase();

        for(var i=0;i<items.length;i++){
            var productName = items[i].name.toLowerCase();

            if(productName.includes(lowCaseFilter)){
                newItems.push({
                    '_id': items[i]._id,
                    'name': items[i].name
                })
            }
        }



        return newItems;
    }
}