import {Component} from "@angular/core";

declare var $: any;

@Component({
    templateUrl: './test.html',
    styleUrls: ['./test.css']
})
export class TestComponent {


    openModal(){
        console.log($('#test'));
        $('#test').modal();
    }
}