import {Component, ChangeDetectorRef} from "@angular/core";
import { HttpClient } from '@angular/common/http';
import {EventsService} from "../services/common/event.service";
import {AuthService} from "../services/auth/auth.service";
import {CustomHeaders} from "../services/common/custom-header.service";

declare var $: any;

@Component({
    templateUrl: './email-template.html',
    styleUrls: ['./email-template.css']
})
export class EmailTemplateComponent{
    public templateContent: any;
    private isEdit:boolean;
    public loading:boolean = true;
    public responseMessage:string;
    public success:boolean;
    public preview:any={
        name: true,
        email: true,
        msisdn: true,
        zipcode: true,
        address: true,
        partner: true,
        gender: true,
        age: true
    };

    public editConfigToolbar: any = {
        "editable": true,
        "spellcheck": true,
        "height": "300px",
        "minHeight": "300px",
        "width": "auto",
        "minWidth": "0",
        "translate": "yes",
        "enableToolbar": true,
        "showToolbar": true,
        "placeholder": "Enter text here...",
        "imageEndPoint": "",
        "toolbar": [
            ["bold", "italic", "underline", "strikeThrough", "superscript", "subscript"],
            ["fontName", "fontSize", "color"],
            ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull", "indent", "outdent"],
            ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
            ["paragraph", "blockquote", "removeBlockquote", "horizontalLine", "orderedList", "unorderedList"],
            ["link", "unlink", "image", "video"]
        ]
    };

    constructor(private auth: AuthService, private http: HttpClient, private eventsService: EventsService, private headers: CustomHeaders){
        this.eventsService.broadcast({
            'key': 'pageChanged',
            'data': 'front'
        });
        this.getTemplate();
    }

    ngOnInit() {

    }

    getTemplate(){
        this.loading = true;
        this.http.get('/api/v1/template/'+this.auth.getCurrentSiteHostName(), this.headers.getCustomHeader())
            .subscribe((data) => {
                this.loading = false;
                this.isEdit = !!data['site_id'];
                if(this.isEdit){
                    this.templateContent = {
                        text: data['text'],
                        sender: data['sender'],
                        subject: data['subject']
                    };
                }
                else{
                    this.templateContent = {};
                }
            }),
            (error) =>{
                this.loading = false;
            };
    }

    saveTemplate(){
        let data = {
            site_hostname: this.auth.getCurrentSiteHostName(),
            text: this.templateContent.text,
            sender: this.templateContent.sender,
            subject: this.templateContent.subject
        };

        if(this.isEdit){
            this.http.put('/api/v1/template', data, this.headers.getCustomHeader())
                .subscribe((data) => {
                    this.loading = false;
                    this.responseMessage = 'Template has been saved';
                    this.success = true;
                    this.hideMessageCountdown();
                }),
                (error) =>{
                    this.loading = false;
                    this.responseMessage = 'Error saving template';
                    this.success = false;
                    this.hideMessageCountdown();
                };
        }
        else{
            this.http.post('/api/v1/template', data, this.headers.getCustomHeader())
                .subscribe((data) => {
                    this.loading = false;
                    this.responseMessage = 'Template has been saved';
                    this.success = true;
                    this.hideMessageCountdown();
                }),
                (error) =>{
                    this.loading = false;
                    this.responseMessage = 'Error saving template';
                    this.success = false;
                    this.hideMessageCountdown();
                };
        }
    }

    hideMessageCountdown(){
        setTimeout(() => {
            this.responseMessage = '';
        }, 10000);
    }

    sendPreview(){
        let data = {
            site_hostname: this.auth.getCurrentSiteHostName(),
            preview: this.preview
        };

        this.http.post('/api/v1/template/send_preview', data, this.headers.getCustomHeader())
            .subscribe((data) => {
                this.loading = false;
                this.responseMessage = 'Preview has been send';
                this.success = true;
                this.hideMessageCountdown();
            }),
            (error) =>{
                this.loading = false;
                this.responseMessage = 'Error sending preview';
                this.success = false;
                this.hideMessageCountdown();
            };
    }

    isValid(){
        if(!this.templateContent){
            return false;
        }
        if(!this.templateContent.sender){
            return false;
        }
        if(!this.templateContent.subject){
            return false;
        }
        if(!this.templateContent.text){
            return false;
        }
        return this.isSyntaxCorrect();
    }

    isSyntaxCorrect(){
        let startBrackets = (this.templateContent.text.match(/\[/g) || []).length;
        let endBrackets = (this.templateContent.text.match(/\]/g) || []).length;
        if(startBrackets!=endBrackets){
            return false;
        }

        //TODO more advanced syntax search

        return true;
    }
}