import {Component, EventEmitter, Input, Output} from "@angular/core";


@Component({
    selector: 'banner-module',
    templateUrl: './banner-module.html',
    styleUrls: ['./banner-module.css']
})

export class BannerModule{
    private base64textString:string='';
    image:string;

    @Input() module: any;
    @Output() saveSite :EventEmitter<any> = new EventEmitter();

    constructor() {
    }


    convertImage(evt){
        var files = evt.target.files;
        var file = files[0];

        if (files && file) {
            var reader = new FileReader();
            reader.onload =this._handleReaderLoaded.bind(this);
            reader.readAsBinaryString(file);
        }
    }

    _handleReaderLoaded(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.base64textString= btoa(binaryString);
        this.image = this.base64textString;
    }
}