import { NgModule }      from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import {LocationStrategy,HashLocationStrategy} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {MainComponent} from "./main/main.component";
import {FrontComponent} from "./front/front.component";
import {AppRoutingModule} from "./app-routing.module";
import {LoginComponent} from "./login/login.component";
import {CustomHeaders} from "./services/common/custom-header.service";
import {Base64Service} from "./services/common/base64.service";
import {AuthManager} from "./services/auth/authmanager";
import {AuthService} from "./services/auth/auth.service";
import {EventsService} from "./services/common/event.service";
import {RdLoading} from "./directives/rd-loading/rd-loading";
import {ModulesComponent} from "./modules/modules.component";
import {OrdersComponent} from "./orders/orders.component";
import {HeaderModule} from "./directives/header-module/header-module.directive";
import {FooterModule} from "./directives/footer-module/footer-module.directive";
import {TextModule} from "./directives/text-module/text-module.directive";
import { ColorPickerModule } from 'ngx-color-picker';
import {ProductListModule} from "./directives/product-list-module/product-list-module.directive";
import {BannerModule} from "./directives/banner-module/banner-module.directive";
import {ItemsComponent} from "./items/items.component";
import {Paging} from "./directives/paging/paging.directive";
import {ItemComponent} from "./item/item.component";
import {ReduceListByStringPipe} from "./pipes/reduceListByStringPipe";
import {SortItemsByPrioirtyPipe} from "./pipes/sortItemsByPriorityPipe";
import {SingleOrderComponent} from "./single-order/single-order.component";
import {ReportsComponent} from "./reports/reports.component";
import {NavModule} from "./directives/nav-module/nav-module";
import {CopyItemComponent} from "./copy-item/copy-item.component";
import {SearchComponent} from "./search/search.component";
import {SettingsComponent} from "./settings/settings.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {TestComponent} from "./test/test.component";
import {EmailTemplateComponent} from "./email-template/email-template.component";
import {NgxEditorModule} from "ngx-editor";
import {MyDatePicker} from "./directives/mydatepicker/my-date-picker";


@NgModule({
  declarations: [
    MainComponent,
    FrontComponent,
    LoginComponent,
    RdLoading,
    HeaderModule,
    FooterModule,
    BannerModule,
    TextModule,
    NavModule,
    Paging,
    ProductListModule,
    ModulesComponent,
    OrdersComponent,
    ItemsComponent,
    ItemComponent,
    CopyItemComponent,
    SingleOrderComponent,
    ReportsComponent,
    ReduceListByStringPipe,
    SortItemsByPrioirtyPipe,
    SearchComponent,
    SettingsComponent,
    TestComponent,
    EmailTemplateComponent,
    MyDatePicker
  ],
  imports:      [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxEditorModule,
    ColorPickerModule
  ],

  bootstrap:    [MainComponent],
  providers:    [{provide: LocationStrategy, useClass: HashLocationStrategy}, CustomHeaders,
    Base64Service, AuthManager, AuthService, EventsService, ReduceListByStringPipe, SortItemsByPrioirtyPipe]
})
export class AppModule { }
