import {Component, EventEmitter, Input, Output, OnInit} from "@angular/core";

@Component({
    selector: 'text-module',
    templateUrl: './text-module.html',
    styleUrls: ['./text-module.css']
})

export class TextModule {
    @Input() module: any;
    @Output() saveSite :EventEmitter<any> = new EventEmitter();

    save(text) {
        this.module.text = text;
        this.saveSite.emit();
    }

    constructor() {}
}
