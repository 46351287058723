import {Component} from "@angular/core";
import { HttpClient } from '@angular/common/http';
import {CustomHeaders} from "../services/common/custom-header.service";
import {EventsService} from "../services/common/event.service";

declare var $: any;

@Component({
    templateUrl: './search.html',
    styleUrls: ['./search.css']
})
export class SearchComponent {

    discountCouponSearchValue:string='';
    discountCoupons:any[];
    loadingDiscountCouponSearch:boolean=false;

    constructor(private headers: CustomHeaders, private http: HttpClient, private eventsService: EventsService){
        this.eventsService.broadcast({
            'key': 'pageChanged',
            'data': 'search'
        });
    }

    searchForDiscountCoupon(){
        this.loadingDiscountCouponSearch = true;
        var url = '/voucherapi/v1/discount-coupons/search/' + this.discountCouponSearchValue;
        this.http.get(url, this.headers.getCustomHeader())
            .subscribe((data) =>{
                    var json:any = data;
                    this.discountCoupons = json;
                    for(var i=0;i<this.discountCoupons.length;i++){
                        this.discountCoupons[i].loadingItems=true;
                        this.getItems(this.discountCoupons[i]);
                    }
                    this.loadingDiscountCouponSearch = false;
                },
                (error) =>{
                    this.loadingDiscountCouponSearch = false;
                });
    }

    getItems(discountCoupon){
        discountCoupon.loadingItems=true;
        var productIdList = '';
        for(var i=0;i<discountCoupon.products.length;i++){
            if (i>0){
                productIdList+=','
            }
            productIdList+=discountCoupon.products[i].product_id;
        }
        var url = '/api/v1/item/list_items_used_by_product_ids/'+productIdList;
        this.http.get(url, this.headers.getCustomHeader())
            .subscribe((data) =>{
                    var json:any = data;
                    discountCoupon.items = json;
                    discountCoupon.loadingItems=false;
                },
                (error) =>{
                    discountCoupon.loadingItems=false;
                });
    }
}