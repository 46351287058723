import {Component} from "@angular/core";
import {AuthService} from "../services/auth/auth.service";
import {CustomHeaders} from "../services/common/custom-header.service";
import {HttpClient} from "@angular/common/http";
import {EventsService} from "../services/common/event.service";


@Component({
    templateUrl: './reports.html',
    styleUrls: ['./reports.css']
})
export class ReportsComponent{
    reports: any[]= [
        { name: 'Choose report', value: 0 },
        { name: 'Item report', value: 1 }
    ];
    selectedReport: any;
    selectedItem: any;
    startDateMyDatePickerOptions: any = {
        todayBtnTxt: 'Today',
        dateFormat: 'dd/mm-yyyy',
        firstDayOfWeek: 'mo',
        sunHighlight: true,
        height: '25px',
        width: '200px',
        inline: false,
        disableUntil: {year: 2016, month: 1, day: 1}
    };

    endDateMyDatePickerOptions: any = {
        todayBtnTxt: 'Today',
        dateFormat: 'dd/mm-yyyy',
        firstDayOfWeek: 'mo',
        sunHighlight: true,
        height: '25px',
        width: '200px',
        inline: false,
        disableUntil: {year: 2016, month: 1, day: 1}
    };

    public selStartDate: string;
    public selEndDate: string;
    public startDateObj: Date;
    public endDateObj: Date;
    private startDate: string;
    private endDate: string;

    private items: any[]=[
        { name: 'Choose item', value: 0, id: null }
    ];
    private report: any={
        email: ''
    };
    private userInputsValid: any= {
        email: false
    };

    constructor(private auth: AuthService, private headers: CustomHeaders, private http: HttpClient, private eventsService: EventsService){
        this.eventsService.broadcast({
            'key': 'pageChanged',
            'data': 'reports'
        });

        // The code below

        // First we get the current date
        let endDate: Date = new Date();

        // Move the end date to yesterday
        endDate.setDate(endDate.getDate()-1);

        // Split date into DD/MM-YYYY parts
        let endDay = endDate.getDate();
        let endMonth = endDate.getMonth() + 1;
        let endYear = endDate.getFullYear();

        // Set selected date on date picker to this value
        this.selEndDate =  this.formatDate(endDate);
        // Also save our date object
        this.endDateObj = endDate;

        // Get current date
        let startDate: Date = new Date();

        // Move the start to last month
        startDate.setMonth(startDate.getMonth()-1);

        // Split date into components
        let startDay = startDate.getDate();
        let startMonth = startDate.getMonth() + 1;
        let startYear = startDate.getFullYear();

        // Set selected date on date picker
        this.startDateObj = startDate;
        this.selStartDate = this.formatDate(startDate);

        // These functions convert the privously assigned
        // date objects (this.startDateObj and this.endDateObj)
        // into YYYY-MM-DDT00:00:00 and then assign it to
        // this.startDate as a string. Ugh.
        this.generateStartDate();
        this.generateEndDate();

        let user = this.auth.getCurrentUser();
        this.report.email = user.username;
        this.isEmailValid();
        this.selectedReport = this.reports[0];
        this.selectedItem = this.items[0];
        this.getItems();
    }

    getItems(){
        var url = '/api/v1/item';
        this.http.get(url, this.headers.getCustomHeader())
            .subscribe((data) =>{
                var json:any = data;
                for(var i=0;i<json.length;i++){
                    this.items.push({
                        name: json[i].name,
                        value: i+1,
                        id: json[i]._id
                    })
                }
            },
            (error) =>{
                console.log('error');
            });
    }

    sendReport(){
        if(this.selectedReport.value === 1){
            let data = {
                id: this.selectedItem.id,
                email: this.report.email,
                start_date: this.startDate,
                end_date: this.endDate
            };
            console.log('data: ', data);

            this.http.post('/api/v1/report/item_report', data, this.headers.getCustomHeader())
                .subscribe((data) => {
                    console.log('success');
                }),
                (error) =>{
                    console.log('failure');
                };
        }
    }

    isEmailValid(){
        setTimeout(() => {
            //var EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
            var EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            this.userInputsValid.email = !(!this.report.email || this.report.email.length <= 5 || !EMAIL_REGEXP.test(this.report.email));
        }, 10);
    }

    onStartDateChanged(event){
        if(event.date && event.date.day && event.date.month && event.date.year){
            this.startDateObj = new Date(event.date.year, event.date.month-1, event.date.day, 0, 0, 0, 0);
        }
        else{
            this.startDateObj = null;
        }
        this.generateStartDate();
    }

    onEndDateChanged(event){
        if(event.date && event.date.day && event.date.month && event.date.year){
            this.endDateObj = new Date(event.date.year, event.date.month-1, event.date.day, 0, 0, 0, 0);
        }
        else{
            this.endDateObj = null;
        }
        this.generateEndDate();
    }

    generateStartDate(){
        if(this.startDateObj == null){
            this.startDate = null;
            return;
        }

        let startDay = (this.startDateObj.getDate() < 10 ? '0'+this.startDateObj.getDate(): this.startDateObj.getDate());
        let startMonth = this.startDateObj.getMonth() + 1;
        let startYear = this.startDateObj.getFullYear();
        this.startDate = startYear + '-' + (startMonth < 10 ? '0'+startMonth: startMonth) + '-' + startDay + 'T00:00:00';
    }

    generateEndDate(){
        if(this.endDateObj == null){
            this.endDate = null;
            return;
        }

        let endDay = (this.endDateObj.getDate() < 10 ? '0'+this.endDateObj.getDate(): this.endDateObj.getDate());
        let endMonth = this.endDateObj.getMonth() + 1;
        let endYear = this.endDateObj.getFullYear();
        this.endDate = endYear + '-' + (endMonth < 10 ? '0'+endMonth: endMonth) + '-' + endDay + 'T23:59:59';
    }

    formatDate(date: Date) {
        // Format date as DD/MM-YYYY
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        return (day < 10 ? '0'+day: day) + '/' + (month < 10 ? '0'+month: month) + '-' + year;
    }

    updateDates() {
        // Check if we selected an item,
        // and not the "Choose Item" text
        if (!this.selectedItem.id) {
            return;
        }

        // Set the selected start date to the
        // date the item was created
        this.http.get('api/v1/item/by_id/'+this.selectedItem.id, this.headers.getCustomHeader())
            .subscribe((data: any) =>{
                // Set selected date to when it was created.
                // One might assume that data.created always
                // is going to be valid, but this hellscape of
                // a program doesn't guarantee it. There are nulls
                // in the production database.
                if (!data.created) {
                    //Just make it the year 2000
                    data.created = "2000"
                }

                this.startDateObj = new Date(data.created);
                this.selStartDate = this.formatDate(this.startDateObj);
                this.generateStartDate();
            },
            (error) =>{
                // We couldn't get a date, so we'll just default
                // to one month ago
                console.log('Error getting start date');
                console.log(error);
                // First we get the current date
                let date: Date = new Date();

                // Move the start to last month
                date.setMonth(date.getMonth()-1);

                //Assign it
                this.startDateObj = date;
                this.selStartDate = this.formatDate(this.startDateObj);
                this.generateStartDate()
            });

        // Set selected end date to the newest order for that item
        this.http.get('api/v1/order/newest_for_item/'+this.selectedItem.id, this.headers.getCustomHeader())
            .subscribe((data: any) =>{
                this.endDateObj = new Date(data);
                this.selEndDate = this.formatDate(this.endDateObj);
                this.generateEndDate();
            },
            (error) =>{
                // We couldn't get a date, so we'll just default
                // to yesterday
                console.log('Error getting end date');
                console.log(error);
                // First we get the current date
                let date: Date = new Date();

                // Move the end date to yesterday
                date.setDate(date.getDate()-1);

                //Assign it
                this.endDateObj = date;
                this.selEndDate = this.formatDate(this.endDateObj);
                this.generateEndDate();
            });
    }
}
