import {Component} from "@angular/core";
import { HttpClient } from '@angular/common/http';
import {CustomHeaders} from "../services/common/custom-header.service";
import {EventsService} from "../services/common/event.service";
import {AuthService} from "../services/auth/auth.service";

declare var $: any;

@Component({
    templateUrl: './settings.html',
    styleUrls: ['./settings.css']
})
export class SettingsComponent {
    public site:any;
    public loadingSite:boolean=true;
    public updated:any;

    constructor(private auth: AuthService, private headers: CustomHeaders, private http: HttpClient, private eventsService: EventsService){
        this.eventsService.broadcast({
            'key': 'pageChanged',
            'data': 'settings'
        });

        this.getSite();
    }

    getSite(){
        this.loadingSite = true;
        console.log(this.auth.getCurrentSiteHostName());

        this.http.get('/api/v1/site/'+this.auth.getCurrentSiteHostName())
            .subscribe((data) => {
                var json:any = data;
                console.log(json);
                this.site = json;
                this.loadingSite = false;
            }),
            (error) =>{
                console.log(error);
                this.loadingSite = false;
            };
    }

    updateSettings(){
        var data = {
            test_mode: this.site.test_mode
        };
        this.http.put('/api/v1/site/site_settings/'+this.site._id, data, this.headers.getCustomHeader())
            .subscribe((data) => {
                this.updated = {
                    msg: 'Settings updated',
                    success: true
                };
                console.log('success');
            }),
            (error) =>{
                this.updated = {
                    msg: 'Settings not updated',
                    success: false
                };
                console.log('failed');
            };
    }
}