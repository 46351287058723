import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {FrontComponent} from "./front/front.component";
import {LoginComponent} from "./login/login.component";
import {AuthManager} from "./services/auth/authmanager";
import {ModulesComponent} from "./modules/modules.component";
import {OrdersComponent} from "./orders/orders.component";
import {SingleOrderComponent} from "./single-order/single-order.component";
import {ItemsComponent} from "./items/items.component";
import {ItemComponent} from "./item/item.component";
import {CopyItemComponent} from "./copy-item/copy-item.component";
import {ReportsComponent} from "./reports/reports.component";
import {SearchComponent} from "./search/search.component";
import {SettingsComponent} from "./settings/settings.component";
import {TestComponent} from "./test/test.component";
import {EmailTemplateComponent} from "./email-template/email-template.component";

const routes: Routes = [
  { path: '', component: FrontComponent, canActivate: [AuthManager]},
  { path: 'login', component: LoginComponent},
  { path: 'modules', component: ModulesComponent},
  { path: 'orders', component: OrdersComponent},
  { path: 'orders/:order_id', component: SingleOrderComponent},
  { path: 'items', component: ItemsComponent},
  { path: 'items/add', component: ItemComponent},
  { path: 'items/copy', component: CopyItemComponent},
  { path: 'items/:id', component: ItemComponent},
  { path: 'reports', component: ReportsComponent},
  { path: 'search', component: SearchComponent},
  { path: 'settings', component: SettingsComponent},
  { path: 'test', component: TestComponent},
  { path: 'email-template', component: EmailTemplateComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
