import {Component, EventEmitter, Input, Output, OnInit} from "@angular/core";


@Component({
    selector: 'header-module',
    templateUrl: './header-module.html',
    styleUrls: ['./header-module.css']
})

export class HeaderModule implements OnInit{
    private base64textString:string='';
    private type:string;
    @Input() module: any;
    @Output() saveSite :EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    ngOnInit(){
        if(!this.module.image_desktop){
            this.module.image_desktop = { base64: ''}
        }
        if(!this.module.image_mobile){
            this.module.image_mobile = { base64: ''}
        }
    }

    convertImage(evt, type){
        var files = evt.target.files;
        var file = files[0];
        this.type = type;
        if (files && file) {
            var reader = new FileReader();
            reader.onload =this._handleReaderLoaded.bind(this);
            reader.readAsBinaryString(file);
        }
    }

    _handleReaderLoaded(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.base64textString= btoa(binaryString);
        if(this.type==='desktop'){
            this.module.image_desktop.base64 = this.base64textString;
        }
        else{
            this.module.image_mobile.base64 = this.base64textString;
        }
        this.saveSite.emit();
    }
}