import {Component} from "@angular/core";
import {Router} from '@angular/router';
import {AuthService} from "../services/auth/auth.service";
import {CustomHeaders} from "../services/common/custom-header.service";
import {EventsService} from "../services/common/event.service";
import {HttpClient} from "@angular/common/http";


@Component({
    templateUrl: './copy-item.html',
    styleUrls: ['./copy-item.css']
})
export class CopyItemComponent {
    itemsToCopy:any[]=[];
    selectedItem:any;
    private eventPlaces:any[]=[];
    private isEdit:boolean=false;
    private item:any={
        user_input_fields: {
            name: true,
            nameRequired: true,
            email: true,
            emailRequired: true,
            address: false,
            addressRequired: false,
            msisdn: false,
            msisdnRequired: false,
            age: false,
            ageRequired: false,
            gender: false,
            genderRequired: false
        }
    };


    constructor(private authService: AuthService, private customHeaders: CustomHeaders, private http: HttpClient, private eventsService: EventsService, private router: Router) {
        this.eventsService.broadcast({
            'key': 'pageChanged',
            'data': 'items'
        });
        this.getAllNoneCopyItems();
        //this.getProducts();
    }

    getAllNoneCopyItems(){
        let headers = this.customHeaders.getCustomHeader();
        this.http.get('/api/v1/item', headers)
            .subscribe((data) => {
                var json:any = data;
                this.itemsToCopy = json;
                this.selectedItem = this.itemsToCopy[0];
            }),
            (error) =>{
                console.log(error);
            };
    }

    copyItem(){
        let headers = this.customHeaders.getCustomHeader();
        var data = {
            'item_id': this.selectedItem._id,
            'site_name': this.authService.getCurrentSiteHostName()
        }
        this.http.post('/api/v1/item/copy_item', data, headers)
            .subscribe((data) => {
                var json = data;
                this.router.navigate(['/items/'+json['id']]);
            }),
            (error) =>{
                console.log(error);
            };
    }

    getItem(urlName){
        this.http.get('/api/v1/item/'+urlName)
            .subscribe((data) => {
                var item = data;
                this.item.id = item['_id'];
                this.item.name = item['name'];
                this.item.urlName = item['url_name'];
                this.item.headerText = item['header_text'];
                this.item.teaserText = item['teaser_text'];
                this.item.teaserImg = item['image'];
                this.item.trailerLink = item['trailer_link'];
                this.item.createdByText = item['created_by_text'];
                this.eventPlaces = item['event_places'];
                this.item.user_input_fields.name = item['user_input_fields.name'];
                this.item.user_input_fields.nameRequired = item['user_input_fields.name_required'];
                this.item.user_input_fields.email = item['user_input_fields.email'];
                this.item.user_input_fields.emailRequired = item['user_input_fields.email_required'];
                this.item.user_input_fields.address = item['user_input_fields.address'];
                this.item.user_input_fields.addressRequired = item['user_input_fields.address_required'];
                this.item.user_input_fields.msisdn = item['user_input_fields.msisdn'];
                this.item.user_input_fields.msisdnRequired = item['user_input_fields.msisdn_required'];
                this.item.user_input_fields.age = item['user_input_fields.age'];
                this.item.user_input_fields.ageRequired = item['user_input_fields.age_required'];
                this.item.user_input_fields.gender = item['user_input_fields.gender'];
                this.item.user_input_fields.genderRequired = item['user_input_fields.gender_required'];

            }),
            (error) =>{
                console.log(error);
            };
    }

}