import { Component } from '@angular/core';
import {AuthService} from "../services/auth/auth.service";
import {EventsService} from "../services/common/event.service";


@Component({
    selector: 'my-app',
    templateUrl: './main.html',
    styleUrls: ['./main.css']
})
export class MainComponent {
    public pageContent: any = {};
    userData: any;
    private siteSelected: any;
    private page: any;

    constructor(private authService: AuthService, private eventsService: EventsService) {
        this.loadCurrentUser();
        this.setSelectedPage('front');

        // Listening on login
        this.eventsService.on()
            .subscribe(message => {
                if(message+'' == 'login'){
                    this.loadCurrentUser();
                }
            });

        // Listening on logout
        this.eventsService.on()
            .subscribe(message => {
                if(message+'' == 'logout'){
                    this.loadCurrentUser();
                }
            });

        // Listening on selectedSite
        this.eventsService.on()
            .subscribe(dict => {
                if(dict.key == 'siteNameUpdated'){
                    this.siteSelected = this.authService.getCurrentSiteHostName();
                }
            });

        // Listening on pageChanged
        this.eventsService.on()
            .subscribe(dict => {
                if(dict.key == 'pageChanged'){
                    this.setSelectedPage(dict.data);
                }
            });

        // Listening on reports
        this.eventsService.on()
            .subscribe(dict => {
                if(dict.key == 'reports'){
                    this.setSelectedPage(dict.data);
                }
            });

        if(this.authService.getCurrentSiteHostName()){
            this.siteSelected = this.authService.getCurrentSiteHostName();
        }
    }

    setSelectedPage(selectedPage){
        this.page = {
            settings: false,
            front: false,
            modules: false,
            orders: false,
            items: false,
            reports: false
        }

        if('front' === selectedPage){
            this.page.front = true;
        }
        else if('settings' === selectedPage){
            this.page.settings = true;
        }
        else if('modules' === selectedPage){
            this.page.modules = true;
        }
        else if('orders' === selectedPage){
            this.page.orders = true;
        }
        else if('items' === selectedPage){
            this.page.items = true;
        }
        else if('reports' === selectedPage){
            this.page.reports = true;
        }
    }

    loadCurrentUser(){
        this.userData = this.authService.getCurrentUser();
    }

    logout(){
        this.authService.clearCredentials();
        this.userData = this.authService.getCurrentUser();
        this.eventsService.broadcast('logout');
    };
}