import {Component} from "@angular/core";
import { Router} from '@angular/router';
import {AuthService} from "../services/auth/auth.service";
import {CustomHeaders} from "../services/common/custom-header.service";
import {EventsService} from "../services/common/event.service";
import {HttpClient} from "@angular/common/http";

declare var $: any;

@Component({
    templateUrl: './items.html',
    styleUrls: ['./items.css']
})
export class ItemsComponent {
    pages:any={};
    loadingItems:boolean = true;
    items:any[]=[];
    itemToDelete:any=null;

    constructor(private auth: AuthService, private headers: CustomHeaders, private http: HttpClient, private eventsService: EventsService, private router: Router) {
        this.eventsService.broadcast({
            'key': 'pageChanged',
            'data': 'items'
        });
        this.reset();
        window.scrollTo(0,0);
        this.showItemList();
    }

    reset(){
        this.pages = {
            offset: 0,
            limit: 10,
            total: 0
        }
    }

    changePage(offset){
        console.log('changing page', offset);
        this.pages.offset = offset;
        this.showItemList();
    }

    showItemList(){
        this.loadingItems = true;

        var search = '';

        if(!search){
            search = 'IGNORE';
        }

        var url = '/api/v1/item/offset/'+this.pages.offset+'/limit/'+this.pages.limit+'/site/'+this.auth.getCurrentSiteHostName()+'/search/'+search;
        this.http.get(url, this.headers.getCustomHeader())
            .subscribe((data) =>{
                    var json = data;
                    this.loadingItems = false;
                    this.items = json['search_data'];
                    this.pages.total = json['total'];
                    this.pages.maxPage = Math.ceil(this.pages.total / this.pages.limit);
                },
                (error) =>{
                    this.loadingItems = false;
                    /*
                    this.alertsData.push({
                        type: 'danger',
                        msg: 'Could not connect to the server, try again later.'
                    });
                    */
                });
    }

    editItem(item){
        this.router.navigate(['/items/'+item._id]);
    }

    setItemToDelete(item){
        this.itemToDelete = item;
    }

    deleteItem(){
        this.http.delete('/api/v1/item/'+this.itemToDelete._id, this.headers.getCustomHeader())
            .subscribe((data) =>{
                this.showItemList();
            },
            (error) =>{
            });
    }
}